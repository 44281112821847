<template>
  <div class="content d-flex flex-col gap-4">
    <h1>Production cost</h1>

    <b-tabs pills v-model="pageIndex" class="d-print-none">
      <b-tab title="Calculator"></b-tab>
      <b-tab title="Template"></b-tab>
    </b-tabs>

    <calculator
      v-if="pageIndex === 0"
      @loaded="onLoaded"
      :load="load"
    />
    <products
      v-if="pageIndex === 1"
      @load="onLoad"
    />
  </div>
</template>

<script>
const Calculator = () => import('@/components/prodcost/Calculator.vue');
const Products = () => import('@/components/prodcost/Products.vue');

export default {
  name: 'ProductionCost',
  components: {
    Calculator,
    Products,
  },
  data() {
    return {
      pageIndex: 0,
      load: {
        id: null,
        type: 'edit',
      },
    };
  },
  methods: {
    onLoad(load) {
      this.load = load;
      this.pageIndex = 0;
    },
    onLoaded() {
      this.load.id = null;
      this.load.type = 'edit';
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
